@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
      font-family: "Poppins", sans-serif;
      background-color: black;
}

.anime {
      color: #fff;
      background: linear-gradient(to right, #3E98FF 50%, #fff 50%);
      background-size: 200% 100%;
      transition: all 1s;
    
    }
    
    .anime:hover {
      background-position: -100% 0;
      color: #111;
      border: 1px solid #111;
    }
    
    .anime2 {
      color: #fff;
      background: linear-gradient(to right, transparent 50%, #3E98FF 50%);
      background-size: 200% 100%;
      transition: all 1s;
      border: 1px solid #303030;
    
      
    }
    
    .anime2:hover {
      background-position: -100% 0;
      color: #090c1c;
      border: 1px solid #303030;
    }
  